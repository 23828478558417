import React from "react";
import {Bars} from "react-loader-spinner";
import "../css/Loader.css";

const customLoader = ({loading}) => {
    return (
        loading && (
            <div style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div className={`loader-container ${loading ? "loading" : ""}`}>
                    <div className="blur-background"/>

                    <Bars
                        wrapperClass={'react-spinner-loader'}
                        height="80"
                        width="80"
                        radius="9"
                        color="black"
                        ariaLabel="loading"
                    />
                </div>
            </div>)
    );
};

export default customLoader;
