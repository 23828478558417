import React from "react";
import {Box, IconButton} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {useProSidebar} from "react-pro-sidebar";
import {useNavigate} from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import useAuth from "../hooks/useAuth";
import {toast} from "react-toastify";
import Cookies from "js-cookie";


const Topbar = () => {
    const {toggleSidebar, broken, rtl} = useProSidebar();
    const navigate = useNavigate();
    const {user, setUser} = useAuth()

    const signOut = () => {
        setUser(null);
        Cookies.remove('user');
        navigate("/signin")
        toast.success("Επιτυχής Αποσύνδεση")
    }
    const hasAdminRights = (user?.role === 'admin' || user?.role === 'superadmin');

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            <Box display="flex">
                {broken && !rtl && (
                    <IconButton sx={{margin: "0 6 0 2"}} onClick={() => toggleSidebar()}>
                        <MenuOutlinedIcon/>
                    </IconButton>
                )}
            </Box>
            <Box className="profile-menu">
                <div>
                    {hasAdminRights && (<IconButton style={{cursor: 'auto', pointerEvents: 'none'}}>
                        <Dropdown>
                            <Dropdown.Toggle className="profile-menu__button" variant="clear" id="dropdown-basic">
                                <SettingsOutlinedIcon/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{cursor: 'pointer', pointerEvents: 'auto', borderRadius: '10px'}}>
                                <Dropdown.Item href="/create-user">Δημιουργία Χρήστη</Dropdown.Item>
                                {user?.role === 'superadmin' &&
                                    <>
                                        <hr style={{margin: '0'}}/>
                                        <Dropdown.Item href="/points-manage">Διαχείριση Βαθμων </Dropdown.Item>
                                    </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </IconButton>)}
                    <IconButton style={{cursor: 'auto', pointerEvents: 'none'}}>
                        <Dropdown>
                            <Dropdown.Toggle className="profile-menu__button" variant="clear" id="dropdown-basic">
                                <PersonOutlinedIcon/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{cursor: 'pointer', pointerEvents: 'auto', borderRadius: '10px'}}>
                                {hasAdminRights && (<><Dropdown.Item href="/adminPanel">Admin Panel</Dropdown.Item>
                                    <hr style={{margin: '0'}}/>
                                </>)}
                                <Dropdown.Item onClick={signOut}>Αποσύνδεση</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </IconButton>

                </div>
                {broken && rtl && (
                    <IconButton sx={{margin: "0 6 0 2"}} onClick={() => toggleSidebar()}>
                        <MenuOutlinedIcon/>
                    </IconButton>
                )}
            </Box>
        </Box>
    )
        ;
};

export default Topbar;
