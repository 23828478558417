import React, {useEffect} from 'react';

import {CssBaseline, ThemeProvider} from "@mui/material";
import {SidebarProvider} from "../global/sidebar/sidebarContext";
import Topbar from "../global/Topbar";
import {ColorModeContext, useMode} from "../theme";
import {Navigate, Outlet, useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import {toast} from "react-toastify";
import Cookies from "js-cookie";

const Layout = ({children, restrictRole}) => {

    const [theme, colorMode] = useMode();
    const {user, setUser} = useAuth()
    const navigate = useNavigate();

    useEffect(() => {

        const token = localStorage.token ? localStorage.token : sessionStorage.token;
        if (!token) {
            setUser(false)
            Cookies.remove('user')
            navigate('/signin')
        } else if (!user || user === false) {
            const storedUser = Cookies.get('user');
            storedUser ? setUser(JSON.parse(storedUser)) : navigate('/signin')
        }
    }, []);

    if (user && restrictRole) {
        const restrictRoles = restrictRole.split(',').map(role => role.trim());
        if (!restrictRoles.includes(user?.role)) {
            toast.error("Δεν Βρέθηκαν Δικαιώματα")
            return (
                <Navigate to="/"/>
            )
        }
    }

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <SidebarProvider>
                    <div style={{backgroundColor: '#fff', height: "100%", width: "100%"}}>
                        <main>
                            <Topbar/>
                            <div style={{backgroundColor: ''}}>
                                <Outlet/>
                            </div>
                        </main>
                    </div>
                </SidebarProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

export default Layout;
