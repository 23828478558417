import {Box, Button, InputLabel, Select, Typography, useMediaQuery, useTheme} from '@mui/material';
import React, {useEffect, useState} from "react";
import {tokens} from "../theme";
import FormControl from "@mui/material/FormControl";
import {getReport, getReportPdf, retrieveUsers} from "../api/user";
import {DatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import MenuItem from '@mui/material/MenuItem';
import "dayjs/locale/el";
import dayjs from "dayjs";
import {BsFiletypeXlsx} from "react-icons/bs";
import {FaRegFilePdf} from "react-icons/fa";
import useAuth from "../hooks/useAuth";

const Reports = () => {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);

    const [values, setValues] = useState({year: dayjs(new Date()), month: null});
    const [users, setUsers] = React.useState([]);
    const [cleared, setCleared] = React.useState(false);
    const {user, setUser} = useAuth()

    const handleChange = id => event => {
        setValues({...values, [id]: event.target.value});
    };

    const printReport = (type) => {
        const obj = transformObject(values)
        if (type === 'xls') {
            getReport(obj.period, obj.user)
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ΠΛΑΤΦΟΡΜΑ_ΑΝΑΦΟΡΕΣ.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            getReportPdf(obj.period, obj.user)
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ΠΛΑΤΦΟΡΜΑ_ΑΝΑΦΟΡΕΣ.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const transformObject = ({year, month, user}) => ({
        period: (month?.$M + 1 ? (month?.$M + 1).toString().padStart(2, '0') : '00')
            + '-' + (year?.$y ? year?.$y : new Date().getFullYear()),
        user: user ? user : 'all',
    });

    useEffect(() => {
        if (user?.role === 'admin' || user?.role === 'superadmin') {
            retrieveUsers()
                .then((res) => {
                    const filteredUsers = res.data.map(userObj => {
                        return {
                            id: userObj._id,
                            username: userObj.username
                        };
                    });
                    setUsers(filteredUsers);
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setUsers([{id: user._id, username: user.username}])
            setValues({...values, ['user']: 'self'})
        }
    }, []);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="el">
                <Typography variant="h4"
                            fontWeight="600"
                            color={colors.grey[100]}
                            style={{marginLeft: '20px'}}>
                    Αναφορά Ανταποδοτικότητας Ανά Χρήστη
                </Typography>
                <Box display={smScreen ? "flex" : "block"}
                     flexDirection={smScreen ? "row" : "column"}
                     justifyContent={smScreen ? "center" : "start"}
                     alignItems={smScreen ? "center" : "start"}
                     m="10px 0">
                    <div style={{
                        overflow: "hidden",
                        padding: "20px",
                        height: "88vh",
                        width: '85vw'
                    }}>
                        <div className='main-top'
                             style={{width: '100%', justifyContent: 'center', display: 'flex', marginTop: '80px'}}>
                            <div style={{
                                display: 'grid',
                                justifyContent: 'center',
                                backgroundColor: '#f8f9fe',
                                padding: '30px',
                                borderRadius: '10px',
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                gridGap: '20px',
                                width: '500px',
                                margin: 'auto'
                            }}>
                                <div>
                                    <DatePicker
                                        sx={{width: 210, marginRight: '10px'}}
                                        label={'Έτος'}
                                        openTo="year"
                                        id='year'
                                        onChange={(val) => setValues({...values, ['year']: val})}
                                        value={values.year}
                                        views={['year']}/>
                                    <DatePicker
                                        sx={{width: 220}}
                                        label={'Μήνας'}
                                        openTo="month"
                                        id='month'
                                        onChange={(val) => setValues({...values, ['month']: val})}
                                        value={values.month}
                                        views={['month']}
                                        slotProps={{
                                            field: {
                                                clearable: true,
                                                onClear: () => setCleared(true)
                                            },
                                        }}
                                    />
                                </div>
                                {user?.role !== 'user' && (<div style={{margin: '0'}}>
                                        <FormControl fullWidth>
                                            <InputLabel>Χρήστης</InputLabel>
                                            <Select
                                                value={values.user}
                                                inputProps={{
                                                    username: '',
                                                    id: '',
                                                }}
                                                defaultValue="all"
                                                label="Χρήστης"
                                                onChange={handleChange('user')}
                                                variant="outlined">
                                                <MenuItem value={'all'}>
                                                    Όλοι
                                                </MenuItem>
                                                {users.map((option) => {
                                                    return <MenuItem key={option.number} value={option.id}>
                                                        {option.username}
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <span style={{fontSize: '10px', color: 'rgba(0, 0, 0, 0.6)'}}>
                                        Επιλεγμένη Περίοδος: {values?.month ? (values.month?.$M + 1).toString().padStart(2, '0') + ' / ' + values?.year.$y : values?.year.$y}
                                    </span>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Button
                                        onClick={() => printReport('xls')}
                                        style={{
                                            width: '150px',
                                            height: '40px',
                                            border: '1px solid gray',
                                            textTransform: 'none',
                                            marginRight: '5px'
                                        }}
                                        endIcon={<BsFiletypeXlsx style={{color: '#188047'}}/>}
                                    >
                                        Εκτύπωση Excel
                                    </Button>
                                    <Button
                                        onClick={() => printReport('pdf')}
                                        style={{
                                            width: '150px',
                                            height: '40px',
                                            border: '1px solid gray',
                                            textTransform: 'none',
                                        }}
                                        endIcon={<FaRegFilePdf style={{color: '#ca1212'}}/>}
                                    >
                                        Εκτύπωση PDF
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </LocalizationProvider>
        </>
    );
};

export default Reports;
