import {Button, Input, InputAdornment} from "@mui/material";
import React, {useState} from "react";
import {createRfid, deleteRfid} from "../api/managePoints";
import {toast} from "react-toastify";
import {RemoveCircleOutline} from "@mui/icons-material";

const ManageRfid = ({userId, rfids, closeModal}) => {

    const [values, setValues] = useState({
        new_rfids: ''
    });

    const handleChange = id => event => {
        setValues({...values, [id]: event.target.value});
    };

    const addRfidFn = () => {
        if (values.new_rfids === '') toast.error("Το πεδίο RFID είναι υποχρεωτικό ")
        else {
            createRfid(userId, values)
                .then(() => {
                    toast.success("Επιτυχής Δημιουργία RFID")
                    closeModal()
                })
                .catch((err) => {
                    console.log(err)
                    toast.error(err.response?.data.message ?? "Σφαλμα")
                })
        }
    };

    const deleteRfidFn = (i) => {
        const body = {
            rfidToDelete: rfids[i]
        }
        deleteRfid(userId, body)
            .then((_) => {
                toast.success("Επιτυχής Διαγραφή RFID")
                closeModal()
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.response?.data.message ?? "Σφαλμα");
            })
    };

    return (
        <>
            <div style={{
                width: '80%',
                display: 'grid',
                justifyContent: 'space-between',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridGap: '10px',
                margin: 'auto'
            }}>

                {rfids.map((rfid, index) => (
                        <div className="input-block" key={rfid.number}>
                            <Input
                                variant="outlined"
                                disableUnderline={true}
                                endAdornment={
                                    <InputAdornment position="end"
                                                    style={{
                                                        cursor: 'pointer !important',
                                                        pointerEvents: 'auto !important'
                                                    }}
                                                    onClick={() => deleteRfidFn(index)}>
                                        <RemoveCircleOutline style={{
                                            cursor: 'pointer',
                                            color: '#d83938'
                                        }}/>
                                    </InputAdornment>
                                }
                                id="rfid" value={rfid} type="text" required/>
                        </div>
                    )
                )}
                <div className="input-block">
                    <Input variant="outlined"
                           id="new_rfids"
                           disableUnderline={true}
                           value={values.new_rfids} placeholder={'Καταχώριση Νέου RFID'}
                           onChange={handleChange('new_rfids')} type="text"
                           required/>
                </div>
            </div>
            <Button style={{
                height: '30px',
                border: '1px solid gray',
                textTransform: 'none',
                position: 'absolute',
                bottom: '10px',
                right: '10px'
            }}
                    onClick={addRfidFn}>
                Καταχώριση RFID
            </Button>
        </>
    );
};

export default ManageRfid;
