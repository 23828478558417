import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../../css/Home.css'
import logo from "../../assets/ensoll-bg.png";
import {useNavigate, useParams} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {userResetPassword} from "../../api/user";

const ResetPassword = () => {

    const [values, setValues] = useState({
        newPassword: '',
        // confirmPass: ''
    });
    const [email, setEmail] = useState()

    const {token} = useParams();
    useEffect(() => {
        setEmail(jwtDecode(token).email)
        if (token) {
            setValues({...values, resetPasswordLink: token});
        }
    }, []);

    const handleChange = name => event => {
        // console.log(event.target.value);
        setValues({...values, [name]: event.target.value});
    };

    const validate = () => {
        // if (!password || !confirmPass) {
        //     toast.error('Οι κωδικοί πρόσβασης είναι υποχρεωτικοί');
        //     return false;
        // }
        // if (password !== confirmPass) {
        //     toast.error('Οι κωδικοί πρόσβασης δεν ταιριάζουν');
        //     return false;
        // }
        return true;
    }
    const navigate = useNavigate();
    const clickReset = event => {
        if (validate()) {
            userResetPassword(values)
                .then((res) => {
                    navigate('/signin')
                    toast.success(`Επιτυχής Επαναφορά Κωδικού Πρόσβασης`);
                })
                .catch((err) => {
                    toast.error(err.response.data.error)
                })
        }

    };

    return (
        <div>
            <section className="forms-section">
                <div className="forms">
                    <div className="form-wrapper is-active">
                        <button type="button" className="switcher switcher-login"
                                style={{cursor: "auto", transform: 'translate(30%, -20px)'}}>
                            Reset Password
                            <span className="underline"></span>
                        </button>
                        <form className="form"
                              style={{backgroundColor: "#fff", transform: 'translate(10%, -20px)', minWidth: '500px'}}>
                            <fieldset>
                                <img alt="logo"
                                     style={{
                                         maxWidth: "250px",
                                         height: "auto",
                                         margin: 'auto',
                                         padding: "20px",
                                         display: 'flex'
                                     }}
                                     src={logo}
                                />
                                <div className="input-block">
                                    <label>Email</label>
                                    <input id="email" disabled value={email} style={{
                                        backgroundColor: '#e3e6e8'
                                    }}
                                           type="email" required/>
                                </div>
                                <div className="input-block">
                                    <label>Password</label>
                                    <input id="newPassword" onChange={handleChange('newPassword')}
                                           value={values.newPassword}
                                           type="password"
                                           required/>
                                </div>
                                {/*<div className="input-block">*/}
                                {/*    <label>Confirm Password</label>*/}
                                {/*    <input id="ConfirmPassword" onChange={handleChange('confirmPass')}*/}
                                {/*           value={confirmPass} type="password"*/}
                                {/*           required/>*/}
                                {/*</div>*/}
                            </fieldset>

                            <button type="button" style={{width: '200px'}} onClick={clickReset}
                                    className="btn-login">Reset Password
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ResetPassword;
