import FormControl from "@mui/material/FormControl";
import {NativeSelect} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import * as echarts from "echarts";

const CustomBarChart = ({options, initData, getBarData, xAxis, title, subtitle,}) => {

    const barChartRef = useRef(null);
    const currentDay = xAxis[new Date().getDay() - 1];

    const getPast7Days = () => {
        let result = [];
        let days = [];

        for (let i = 6; i >= 0; i--) {
            let date = new Date();
            date.setDate(date.getDate() - i);
            let formattedDate = date.toLocaleDateString('el-GR', {
                day: '2-digit',
                month: '2-digit'
            });
            let dayIndex = (date.getDay() + 6) % 7;
            days.push({
                dayName: xAxis[dayIndex].substring(0, 3),
                date: formattedDate,
                index: dayIndex
            });
        }
        // days.sort((a, b) => a.index - b.index);
        result = days.map(day => `${day.dayName}\n(${day.date})`);

        return result;
    };
    const [barXAxis, setBarXAxis] = useState([]);

    useEffect(() => {
        setBarXAxis(getPast7Days());
    }, []);

    useEffect(() => {
        const barChart = echarts.init(barChartRef.current);
        barChart.setOption({
            title: {
                text: title,
                subtext: subtitle,
                left: 'center'
            },
            legend: {
                top: "bottom"
            },
            grid: {
                left: 50,
                right: 50,
                top: 50,
                bottom: 50
            },
            yAxis: {
                type: 'value'
            },
            xAxis: {
                type: 'category',
                data: barXAxis,
                axisLabel: {
                    interval: 0,
                    align: 'center'
                }
            },
            series: Object.keys(initData).map((name, sid) => {
                const colors = {
                    'ΠΛΑΣΤΙΚΟ': '#fac858',
                    'ΧΑΡΤΙ': '#5470c6',
                    'ΜΕΤΑΛΛΟ': '#ee6666',
                    'ΓΥΑΛΙ': '#91cc75'
                };
                return {
                    name,
                    type: 'bar',
                    stack: 'total',
                    barWidth: '60%',
                    label: {
                        show: true,
                        formatter: (params) => Math.round(params.value * 100) / 100
                    },
                    itemStyle: {
                        color: colors[name]
                    },
                    data: Object.values(initData)[sid].map((d, did) => {
                        return ({
                            value: d,
                            itemStyle: {color: colors[name]}
                        })
                    })
                };
            })
        });
        return () => {
            barChart.dispose();
        };
    }, [initData, xAxis, title, subtitle, currentDay]);


    const handleChange = (event) => {
        getBarData(event.target.value)
    };

    return (
        <>
            <div ref={barChartRef} style={{width: '100%', height: '350px', display: 'flex', justifyContent: 'center'}}/>
            {options &&
                <FormControl style={{width: '100px', position: 'absolute', top: '10px', right: '10px'}}>
                    <NativeSelect
                        defaultValue={options[0]}
                        onChange={handleChange}
                        inputProps={{
                            name: '',
                            id: '',
                        }}
                    >
                        {options.map((option) => {
                            return <option key={option.number} value={option.id}>
                                {option.name}
                            </option>
                        })}
                    </NativeSelect>
                </FormControl>
            }
        </>
    );
};

export default CustomBarChart;
