import React from 'react';
import Modal from 'react-bootstrap/Modal';

const CustomModal = ({title, children, closeModal}) => {

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {closeModal});
        }
        return child;
    });
    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {childrenWithProps}
            </Modal.Body>
        </div>
    );
}

export default CustomModal;
