import React, {useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';

import {Box, Tooltip, Typography, useMediaQuery, useTheme} from "@mui/material";
import {tokens} from "../theme";
import Grid from "@mui/material/Unstable_Grid2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {createMRTColumnHelper, MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_EL} from "material-react-table/locales/el";
import {retrieveUsers, userInfo, userUpdate} from "../api/user";
import EditIcon from "@mui/icons-material/Edit";
import {toast} from "react-toastify";
import CustomModal from "./CustomModal";
import Modal from "react-bootstrap/Modal";
import ManageRfid from "../components/ManageRfid";

const columnHelper = createMRTColumnHelper();

const AdminPanel = () => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(1);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [rfids, setRFID] = useState();
    const [rfidUser, setRfidUser] = useState();
    const [searchTerm, setSearchTerm] = useState('');

    const openModal = (row, renderedCellValue) => {
        setRfidUser(row.original._id)
        setRFID(renderedCellValue)
        setIsOpen(true)
    }

    const closeModal = () => {
        setReload(reload + 1)
        setIsOpen(false);
    }

    useEffect(() => {
        retrieveUsers()
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [reload]);

    const updateUser = (values) => {
        userUpdate(values._id, values)
            .then((_) => {
                toast.success("Επιτυχής Ενημέρωση")
            })
            .catch(err => {
                console.log(err)
                toast.error(err.response.data.message ?? "Σφαλμα");
            })
            .finally(() => setReload(reload + 1))
    }

    const columns = [
        columnHelper.accessor('_id', {header: 'ID', enableEditing: false}),

        columnHelper.accessor('username', {header: 'username'}),
        columnHelper.accessor('email', {header: 'email'}),
        columnHelper.accessor('rfids', {
            header: 'RFID List',
            id: 'rfid',
            enableEditing: false,
            Cell: ({renderedCellValue, row}) => (
                <VisibilityIcon onClick={() => openModal(row, renderedCellValue)} style={{cursor: 'pointer'}}/>
            ),
        }),
        columnHelper.accessor('role', {header: 'role',enableEditing: false}),
        columnHelper.accessor((row) => new Date(row.createdAt).toLocaleString('en-GB', {
            timeZone: 'Europe/Athens',
            hour12: false
        }), {
            header: 'createdAt',
            id: 'createdAt',
            enableEditing: false
        }),
        columnHelper.accessor((row) => new Date(row.updatedAt).toLocaleString('en-GB', {
            timeZone: 'Europe/Athens',
            hour12: false
        }), {
            header: 'updatedAt',
            id: 'updatedAt',
            enableEditing: false
        }),

    ];

    const CustomActions = () => {
        return (<></>);
    }


    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: true,
        editDisplayMode: 'row',
        filterFns: {
            myCustomFilterFn: (row, id, filterValue) => {
                for (const key in row.original) {
                    if (Object.hasOwn(row.original, key)) {
                        const value = row.original[key];
                        if (Array.isArray(value)) {
                            if (value.includes(filterValue)) {
                                return true;
                            }
                        } else if (typeof value === 'string') {
                            if (value.includes(filterValue)) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
        },
        globalFilterFn: 'myCustomFilterFn',
        renderRowActions: ({row}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title={<span style={{fontSize: '14px'}}>Edit </span>}><EditIcon
                    style={{cursor: 'pointer'}} onClick={() => table.setEditingRow(row)}/></Tooltip>
                {/*<Tooltip title={<span style={{fontSize: '14px'}}>Προβολή Στατιστικών</span>}>*/}
                {/*    <VisibilityIcon onClick={() => getUser(row.original._id)} style={{cursor: 'pointer'}}/>*/}
                {/*</Tooltip>*/}
            </Box>
        ),
        onEditingRowSave: ({table, values, row}) => {
            updateUser(values)
            table.setEditingRow(null);
        },
        positionActionsColumn: 'last',
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        enableRowSelection: true,
        enableEditing: true,
        positionToolbarAlertBanner: 'bottom',
        autoResetPageIndex: false,
        autoResetExpanded: false,
        renderTopToolbarCustomActions: ({table}) => (
            <CustomActions/>
        ),
        localization: {...MRT_Localization_EL},

    });

    const getUser = (userId) => {
        userInfo(userId)
            .then((res) => {
                console.log(res)
            })
    };

    return (
        <div style={{height: '92vh'}}>
            <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
                style={{marginLeft: '20px'}}
            >
                Admin Panel
            </Typography>
            <Box m="20px">
                <Box
                    display={smScreen ? "flex" : "block"}
                    flexDirection={smScreen ? "row" : "column"}
                    justifyContent={smScreen ? "space-between" : "start"}
                    alignItems={smScreen ? "center" : "start"}
                    m="10px 0"
                >
                </Box>

                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid
                        xs={12}
                        sm={12}
                        md={8}
                        lg={12}
                        container
                        rowSpacing={1}
                        columnSpacing={{xs: 1, sm: 2, md: 3}}
                    >
                        <Grid xs={12}>
                            <Box backgroundColor={colors.primary[400]} style={{borderRadius: '10px'}}>

                                <Box
                                    mt="25px"
                                    p="0 30px"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box style={{padding: '10px', width: '100%'}}>
                                        <Typography
                                            variant="h5"
                                            fontWeight="600"
                                            color={colors.grey[100]}
                                            style={{padding: '10px'}}
                                        >
                                            Ενεργοί Χρήστες
                                        </Typography>
                                        <MaterialReactTable table={table}/>
                                    </Box>
                                </Box>
                            </Box>
                            <Modal show={modalIsOpen}
                                   onHide={closeModal}
                                   size="lg"
                                   aria-labelledby="contained-modal-title-vcenter"
                                   centered>
                                <CustomModal title='Διαχείριση RFID' closeModal={closeModal}>
                                    <ManageRfid userId={rfidUser} rfids={rfids}/>
                                </CustomModal>
                            </Modal>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default AdminPanel;
