import React, {useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../../css/Home.css'
import logo from "../../assets/ensoll-bg.png";
import {userForgotPassword} from "../../api/user";

const ForgotPassword = () => {

    const [values, setValues] = useState({
        email: ''
    });

    const {email} = values;

    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value});
    };

    const clickForgot = event => {
        event.preventDefault();
        userForgotPassword(values)
            .then((res) => {
                toast.success(`A Confirmation Link has been sent to your Email address `);
            })
            .catch((err) => {
                toast.error(err.response.data.error)
            })
    };

    return (
        <div>
            <section className="forms-section">
                <div className="forms">
                    <div className="form-wrapper is-active">
                        <button type="button" className="switcher switcher-login"
                                style={{cursor: "auto", transform: 'translate(30%, -20px)'}}>
                            Forgot Password
                            <span className="underline"></span>
                        </button>
                        <form className="form"
                              style={{backgroundColor: "#fff", transform: 'translate(10%, -20px)', minWidth: '500px'}}>
                            <fieldset>
                                <img alt="logo"
                                     style={{
                                         maxWidth: "250px",
                                         height: "auto",
                                         margin: 'auto',
                                         padding: "20px",
                                         display: 'flex'
                                     }}
                                     src={logo}
                                />
                                <div className="input-block">
                                    <label>E-mail</label>
                                    <input id="login-email" onChange={handleChange('email')} value={email} type="email"
                                           required/>
                                </div>
                            </fieldset>

                            <button type="button" style={{width: '200px'}} onClick={clickForgot}
                                    className="btn-login">Reset Password
                            </button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ForgotPassword;
