import React from "react";
import NotFoundImage from '../assets/1581099611064.jpeg'; // Import your 404 image

const PageNotFound = () => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '92vh',
    };

    const textStyle = {
        textAlign: 'center',
        marginBottom: '20px',
    };

    const paragraphStyle = {
        fontSize: '18px',
        color: '#555',
    };

    const imageStyle = {
        width: '800px',
        borderRadius: '10px',
        marginBottom: '20px',
    };

    return (
        <div style={containerStyle}>
            <div style={textStyle}>
                <img src={NotFoundImage} alt="404" style={imageStyle}/>
                <p style={paragraphStyle}>Sorry, the page you are looking for does not exist.</p>
                <a href='/' style={{
                    textDecoration: 'none',
                    color: '#007bff',
                    borderRadius: '10px',
                    border: '1px solid gray',
                    padding: '10px 15px',
                    backgroundColor: '#f6f6fe',
                    width: '200px'
                }}>Return to Home</a>
            </div>
        </div>
    );
}

export default PageNotFound;
