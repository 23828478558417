import {createMRTColumnHelper, MaterialReactTable, useMaterialReactTable,} from 'material-react-table';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import React, {useState} from "react";
import {tokens} from "../theme";
import {MRT_Localization_EL} from 'material-react-table/locales/el';
import {getWeightFromSearch} from "../api/weight";
import SearchIcon from '@mui/icons-material/Search';

const columnHelper = createMRTColumnHelper();


const columns = [
    columnHelper.accessor('blockchain_id', {header: 'Blockchain ID'}),
    columnHelper.accessor('serial_num', {header: 'Κάδος'}),
    columnHelper.accessor('type', {header: 'Ανακυκλώσιμο Υλικό'}),
    columnHelper.accessor('date', {header: 'Ημερομηνία'}),
    columnHelper.accessor('time', {header: 'Ώρα'}),
    columnHelper.accessor('rfid', {header: 'RFID'}),
    columnHelper.accessor('user', {header: 'Χρήστης'}),
    columnHelper.accessor('weight', {header: 'Βάρος (kg)'}),
    columnHelper.accessor('points', {header: 'Πόντοι Επιβράβευσης'}),
];

const SearchEntries = () => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');

    // const csvConfig = mkConfig({
    //     fieldSeparator: ',',
    //     decimalSeparator: '.',
    //     useKeysAsHeaders: true,
    // });
    //
    // const handleExportRows = (rows) => {
    //
    //     const tableHeaders = columns.map((c) => c.header);
    //     const csvHeaders = tableHeaders || Object.keys(rows[0].original);
    //
    //     const rowData = [csvHeaders, ...rows.map((row) => {
    //         return Object.values(row.original).map(value => {
    //             if (typeof value === 'object' && value !== null) {
    //                 return value.toString();
    //             } else {
    //                 return value;
    //             }
    //         });
    //     })];
    //     const csv = generateCsv(csvConfig)(rowData);
    //     download(csvConfig)(csv);
    // };

    const handleInputChange = (e) => {
        e.preventDefault();
        // console.log(e.target.value)
        setSearchTerm(e.target.value);
    };

    const fetchSearchResults = () => {
        getWeightFromSearch(searchTerm)
            .then((res) => {
                setData([res.data.weight])
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // useEffect(() => {
    // getWeightFromSearch(searchTerm)
    //     .then((res) => {
    //         console.log(res.data.weight)
    //         setData(res.data.weight)
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     })
    // }, []);

    // const handleExportRowsPdf = (rows) => {
    //     const doc = new jsPDF({orientation: "landscape",});
    //     const tableData = rows.map((row) => Object.values(row.original));
    //     const tableHeaders = columns.map((c) => c.header);
    //     autoTable(doc, {
    //         head: [tableHeaders],
    //         body: tableData,
    //     });
    //
    //     doc.save('mrt-pdf-example.pdf');
    // };


    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: true,
        positionActionsColumn: 'last',
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        localization: {...MRT_Localization_EL},
        renderTopToolbarCustomActions: ({table}) => (
            <></>
        ),
    });


    return (
        <>
            <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
                style={{marginLeft: '20px'}}
            >
                Αναζήτηση Καταχώρησης
            </Typography>
            <div className='main-top' style={{width: '100%', justifyContent: 'center', display: 'flex'}}>
                <div style={{
                    marginTop: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    padding: '5px',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                    width: '1000px',
                }}
                     className="search-bar">
                    <input
                        style={{
                            color: 'black',
                            flexGrow: '1',
                            border: 'none',
                            outline: 'none',
                            fontSize: '16px',
                            padding: '5px'
                        }}
                        className="search-input"
                        type="text"
                        placeholder="Αναζήτηση Blockchain ID"
                        name="id"
                        value={searchTerm}
                        onChange={handleInputChange}
                    />
                    <div
                        style={{
                            cursor: 'pointer',
                            padding: '5px',
                            color: '#777'
                        }}
                        className="search-prefix"
                        onClick={fetchSearchResults}
                    >
                        <SearchIcon/>
                    </div>
                </div>
            </div>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "center" : "start"}
                alignItems={smScreen ? "center" : "start"}
                m="10px 0"
            >

                <div
                    style={{
                        overflow: "hidden",
                        padding: "20px",
                        height: "88vh",
                        width: '85vw'
                    }}
                >
                    <MaterialReactTable table={table}/>
                </div>
            </Box>
        </>
    );
};

export default SearchEntries;
