import React, {useEffect} from 'react';
import 'leaflet/dist/leaflet.css';
import {Box, TextField, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import Grid from "@mui/material/Unstable_Grid2";
import {createUser} from "../api/user";
import {toast} from "react-toastify";

const CreateUser = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [values, setValues] = React.useState({});

    const handleChange = (event) => {
        setValues({...values, [event.target.name]: event.target.value});
    };

    const generateUsername = () => {
        const englishLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let username = '';
        for (let i = 0; i < 2; i++) username += englishLetters[Math.floor(Math.random() * englishLetters.length)];
        for (let i = 0; i < 4; i++) username += Math.floor(Math.random() * 10);
        return username;
    }
    

    useEffect(() => {
        setValues({
            username: generateUsername(),
            email: '',
            password: ''
        })
    }, []);

    const submit = () => {
        createUser(values)
            .then((res) => {
                toast.success('Επιτυχής Δημιουργία Χρήστη')
            })
            .catch((err) => {
                console.log(err)
                toast.error(err.message);
            })
    }
    return (
        <div style={{height: '92vh'}}>
            <Box m="20px">
                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid
                        xs={12}
                        sm={12}
                        md={8}
                        lg={12}
                        container
                        rowSpacing={1}
                        columnSpacing={{xs: 1, sm: 2, md: 3}}
                    >
                        <Grid xs={12}>
                            <Box>
                                <Box
                                    mt="25px"
                                    p="0 30px"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box style={{padding: '10px', width: '100%'}}>
                                        <Typography
                                            variant="h5"
                                            fontWeight="600"
                                            color={colors.grey[100]}
                                            style={{padding: '10px'}}
                                        >
                                            Δημιουργία Χρηστών
                                        </Typography>
                                        <div style={{
                                            display: 'grid',
                                            justifyContent: 'center',
                                            backgroundColor: '#f8f9fe',
                                            padding: '30px',
                                            borderRadius: '10px',
                                            gridTemplateColumns: 'repeat(1, 1fr)',
                                            gridGap: '20px',
                                            width: '500px',
                                            margin: 'auto'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <TextField style={{width: '400px'}} id="standard-basic"
                                                           disabled='disabled'
                                                           helperText="Όνομα Χρήστη"
                                                           value={values.username}
                                                           variant="standard" name="username"/>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <TextField style={{width: '400px'}} id="standard-basic"
                                                           helperText="Email"
                                                           onChange={handleChange}
                                                           value={values.email} variant="standard" name="email"/>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <TextField style={{width: '400px'}} id="standard-basic"
                                                           onChange={handleChange}
                                                           helperText="Κωδικός Χρήστη"
                                                           value={values.password}
                                                           variant="standard" name="password"/>
                                            </div>
                                            <button style={{
                                                justifyContent: 'center', margin: '30px auto 10px',
                                                display: 'block',
                                                minWidth: '120px',
                                                fontSize: '14px',
                                                lineHeight: '40px',
                                                borderRadius: '25px',
                                                border: 'none',
                                                padding: '5px 10px',
                                                color: '#196400',
                                                background: ' #fbfdff',
                                                boxShadow: 'inset 0 0 0 2px #196400'
                                            }} type='button' onClick={submit}>Δημιουργία Χρήστη
                                            </button>
                                        </div>
                                    </Box>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default CreateUser;
