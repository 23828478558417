
import {axiosInstance} from "../boot/axios";


export async function retreiveWeights(id) {
    return await axiosInstance.get(`/api/retrieve_weights?period=${id}`)
}


export async function addWeight() {
    return await axiosInstance.post("/api/add_system")
}

export async function updateWeight(id, body) {
    return await axiosInstance.patch(`/api/updateWeight/${id}`, body)
}


export async function getWeightById(id) {
    return await axiosInstance.get(`/api/weights/${id}`)
}

export async function getWeightFromSearch(id) {
    return await axiosInstance.get(`/api/get_weight/${id}`)
}


