import {useEffect, useState} from "react";
import {Menu, MenuItem, Sidebar, useProSidebar} from "react-pro-sidebar";
import {Link, useLocation} from 'react-router-dom';
import {useSidebarContext} from "./sidebarContext";
import {tokens} from "../../theme";
import logo from '../../assets/ensoll-bg.png';
import {Box, IconButton, Typography, useTheme} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AssessmentIcon from '@mui/icons-material/Assessment';
import useAuth from "../../hooks/useAuth";

const Item = ({title, to, icon, selected, setSelected}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <MenuItem
            active={selected === to}
            style={{color: colors.grey[100]}}
            onClick={() => setSelected(to)}
            icon={icon}
            routerLink={<Link to={to}/>}
        >
            <Typography>{title}</Typography>
        </MenuItem>
    );
};

const MySidebar = () => {
    const theme = useTheme();
    const {user, setUser} = useAuth()
    const location = useLocation();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("/dashboard");
    const {sidebarRTL, sidebarImage} = useSidebarContext();
    const {collapseSidebar, toggleSidebar, collapsed, broken} = useProSidebar();

    useEffect(() => {
        setSelected(location.pathname)
    }, []);

    return (
        <Box
            sx={{
                position: "sticky",
                display: "flex",
                height: "100vh",
                top: 0,
                bottom: 0,
                zIndex: 10000,
                "& .sidebar": {
                    border: "none",
                },
                "& .menu-icon": {
                    backgroundColor: "transparent !important",
                },
                "& .menu-item": {
                    backgroundColor: "transparent !important",
                },
                "& .menu-anchor": {
                    color: "inherit !important",
                    backgroundColor: "transparent !important",
                },
                "& .menu-item:hover": {
                    color: `${colors.blueAccent[500]} !important`,
                    backgroundColor: "transparent !important",
                },
                "& .menu-item.active": {
                    color: `${colors.greenAccent[500]} !important`,
                    backgroundColor: "transparent !important",
                },
            }}
        >
            <Sidebar
                breakPoint="md"
                rtl={sidebarRTL}
                backgroundColor={colors.primary[400]}
                image={sidebarImage}
            >
                <Menu iconshape="square">
                    <MenuItem
                        icon={
                            collapsed ? (
                                <MenuOutlinedIcon onClick={() => collapseSidebar()}/>
                            ) : (
                                <></>
                            )
                        }
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],
                        }}
                    >
                        {!collapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <a href="/">
                                    <img
                                        alt=""
                                        width="110px"
                                        height="55px"
                                        src={logo}
                                        style={{cursor: 'pointer'}}
                                    />
                                </a>

                                <IconButton
                                    onClick={
                                        broken ? () => toggleSidebar() : () => collapseSidebar()
                                    }>
                                    <CloseOutlinedIcon/>
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    <Box>
                        {!collapsed && (<Typography
                            variant="h6"
                            color={colors.grey[300]}
                            sx={{m: "15px 20px 5px 20px"}}>
                            Σελίδες
                        </Typography>)}
                        <Item
                            title="Dashboard"
                            to="/"
                            icon={<HomeOutlinedIcon/>}
                            selected={selected}
                            setSelected={setSelected}/>

                        {user?.role !== 'user' &&
                            <>
                                <Item
                                    title="Αναζήτηση Καταχώρισης"
                                    to="/search-weight"
                                    icon={<PersonOutlinedIcon/>}
                                    selected={selected}
                                    setSelected={setSelected}/>
                            </>
                        }
                        <Item
                            title="Καταχωρίσεις"
                            to="/entries"
                            icon={<CalendarTodayOutlinedIcon/>}
                            selected={selected}
                            setSelected={setSelected}/>
                        <Item
                            title="Αναφορές"
                            to="/reports"
                            icon={<AssessmentIcon/>}
                            selected={selected}
                            setSelected={setSelected}/>
                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    );
};

export default MySidebar;
