import React, {useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Layout from "./core/Layout";
import Signin from "./pages/auth/Signin";
import Entries from "./pages/Entries";
import Detailed from "./pages/Detailed";
import AdminPanel from "./pages/AdminPanel";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import CreateUser from "./pages/CreateUser";
import CustomLoader from "./hooks/CustomLoader";
import PointsManage from "./pages/PointsManage";
import {AuthProvider} from "./hooks/useAuth";
import PageNotFound from "./pages/PageNotFound";
import Activate from "./pages/auth/Activate";
import SearchEntries from "./pages/SearchEntries";
import Reports from "./pages/Reports";
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 1500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return (
        <>
            <AuthProvider>
                <Routes>
                    <Route path="/signin" element={<Signin/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route path="/activation/:token" element={<Activate/>}/>
                    <Route path="/reset-password/:token" element={<ResetPassword/>}/>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Dashboard/>}/>
                        <Route path="/entries" element={<Entries/>}/>
                        <Route path="/reports" element={<Reports setLoading={setLoading}/>}/>
                    </Route>


                    <Route path="/" element={<Layout restrictRole={'superadmin, admin'}/>}>
                        <Route path="/adminPanel" element={<AdminPanel/>}/>
                        <Route path="/create-user" element={<CreateUser/>}/>
                        <Route path="/search-weight" element={<SearchEntries setLoading={setLoading}/>}/>
                        <Route path="/detailed">
                            <Route index element={<Detailed/>}/>
                            <Route path="/detailed/:id" element={<Detailed setLoading={setLoading}/>}
                            />
                        </Route>
                    </Route>

                    <Route path="/" element={<Layout restrictRole={'superadmin'}/>}>
                        <Route path="/points-manage" element={<PointsManage/>}
                               setLoading={setLoading}/>
                    </Route>
                    <Route path="/*" element={<PageNotFound/>}/>
                </Routes>
                <CustomLoader loading={loading}/>
            </AuthProvider>
        </>
    );
    // }


};

export default App;
