import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.min.css';
import '../../css/Home.css'
import logo from "../../assets/ensoll-bg.png";
import {useNavigate, useParams} from "react-router-dom";
import {userAccountActivation} from "../../api/user";
import {toast} from "react-toastify";

const Activate = ({match}) => {
    const [values, setValues] = useState({
        token: ''
    });


    const {token} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            setValues({...values, token});
        }
        const jwt = {
            token: token
        }
        userAccountActivation(jwt)
            .then((res) => {
                toast.success("Ο λογαριασμός ενεργοποιήθηκε επιτυχώς");
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.response.data.message ?? "Σφαλμα");
            })
            .finally(() => {
                navigate('/signin')
            })
    }, []);


    return (
        <div>
            <section className="forms-section">
                <div className="forms">
                    <div className="form-wrapper is-active">
                        <form className="form"
                              style={{backgroundColor: "#fff", transform: 'translate(10%, -20px)', minWidth: '500px'}}>
                            <fieldset>
                                <img alt="logo"
                                     style={{
                                         maxWidth: "250px",
                                         height: "auto",
                                         margin: 'auto',
                                         padding: "20px",
                                         display: 'flex'
                                     }}
                                     src={logo}
                                />

                            </fieldset>

                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Activate;
