import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../../css/Home.css'
import Signup from "./Signup";
import logo from "../../assets/ensoll-bg.png";
import {userSignIn} from "../../api/user";
import FileOpenIcon from '@mui/icons-material/FileOpen';
import {Tooltip} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';

const Signin = () => {

    useEffect(() => {
        const switchers = [...document.querySelectorAll('.switcher')]

        switchers.forEach(item => {
            item.addEventListener('click', function () {
                switchers.forEach(item => item.parentElement.classList.remove('is-active'))
                this.parentElement.classList.add('is-active')
            })
        })

    }, []);
    const [values, setValues] = useState({
        username: '',
        password: ''
    });

    const {username, password} = values;

    const handleChange = name => event => {
        // console.log(event.target.value);
        setValues({...values, [name]: event.target.value});
    };

    const {setUser} = useAuth()
    const navigate = useNavigate();
    const clickLogin = event => {
        event.preventDefault();
        userSignIn(values)
            .then((res) => {
                localStorage.setItem("token", res.data.token)
                sessionStorage.setItem("token", res.data.token)
                Cookies.set('user', JSON.stringify(res.data.user), {expires: 1, secure: true, SameSite: 'Strict'});
                setUser(res.data.user)
                navigate(res.data.user.role === 'user' ? "/entries" : "/");
                toast.success(`Hey ${res.data.user.username}, Welcome back!`);
            })
            .catch((err) => {
                toast.error(err.response.data.error)
            })
    };

    return (
        <div>
            <section className="forms-section">
                <div className="forms">
                    <div className="form-wrapper is-active">
                        <button type="button" className="switcher switcher-login">
                            Συνδεση
                            <span className="underline"></span>
                        </button>
                        <form className="form form-login">
                            <fieldset>
                                <img alt="logo"
                                     style={{maxWidth: "230px", height: "auto", padding: "20px"}}
                                     src={logo}
                                />
                                <div className="input-block" style={{width: '250px'}}>
                                    <label>Όνομα Χρήστη</label>
                                    <input id="login-username" onChange={handleChange('username')} value={username}
                                           type="text"
                                           required/>
                                </div>
                                <div className="input-block" style={{width: '250px'}}>
                                    <label>Κωδικός</label>
                                    <input id="login-password" onChange={handleChange('password')} value={password}
                                           type="password" required/>
                                </div>
                                <a href="/forgot-password" style={{
                                    alignSelf: "start",
                                    fontSize: "14px",
                                    color: "rgba(187,187,187,1)"
                                }}>
                                    Ξέχασες τον κωδικό?
                                </a>
                            </fieldset>

                            <button type="button" onClick={clickLogin} className="btn-login">Σύνδεση</button>
                            <a href="#" style={{
                                fontSize: "12px",
                                color: "#1e6809",
                                display: "block",
                                margin: "30px 0 0 250px",
                            }}>
                                <Tooltip title={'Documentation'}>
                                    <FileOpenIcon/>
                                </Tooltip>
                            </a>
                        </form>
                    </div>
                    <div className="form-wrapper">
                        <button type="button" className="switcher switcher-signup">
                            Εγγραφη
                            <span className="underline"></span>
                        </button>
                        <Signup/>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Signin;
