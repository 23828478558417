import {Button} from "@mui/material";
import React, {useState} from "react";
import {addPoints} from "../api/managePoints";
import {toast} from "react-toastify";

const AddPoints = ({ closeModal }) => {

    const [values, setValues] = useState({
        name: '',
        points: ''
    });

    const handleChange = id => event => {
        setValues({...values, [id]: event.target.value});
    };

    const addPointFn = () => {
        addPoints(values)
            .then((_) => {
                toast.success("Επιτυχής Δημιουργία Εγγραφής")
                closeModal()
            })
            .catch((err) => {
                toast.error(err.response.data.message ?? "Σφαλμα");
            })
    };
    return (
        <>
            <div style={{
                width: '80%',
                height: '200px',
                display: 'grid',
                justifyContent: 'space-between',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridGap: '20px',
                margin: 'auto'

            }}>
                <div className="input-block">
                    <label>Τύπος</label>
                    <input id="name" value={values.name}
                           onChange={handleChange('name')} type="text"
                           required/>
                </div>
                <div className="input-block">
                    <label>Βαθμός</label>
                    <input id="points" value={values.points}
                           onChange={handleChange('points')} type="number"
                           required/>
                </div>

            </div>
            <Button style={{
                height: '30px',
                border: '1px solid gray',
                textTransform: 'none',
                position: 'absolute',
                bottom: '10px',
                right: '10px'
            }}
                    onClick={addPointFn}>
                Δημιουργία Εγγραφής
            </Button>
        </>
    );
};

export default AddPoints;
