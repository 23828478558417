import FormControl from "@mui/material/FormControl";
import {NativeSelect} from "@mui/material";
import React, {useEffect, useRef} from "react";
import * as echarts from "echarts";

const CustomPieChart = ({options, initData, getPieData, title, subtitle}) => {
    const handleChange = (event) => {
        getPieData(event.target.value)
    };
    const pieChartRef = useRef(null);

    useEffect(() => {
        if (Object.keys(initData).length > 0) {
            const pieChart = echarts.init(pieChartRef.current);

            pieChart.setOption({
                title: {
                    text: title,
                    subtext: subtitle,
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => ` ${params.marker} ${params.name} <br/> ${params.data.value.toFixed(1)} (${params.percent}%)`
                },
                series: [{
                    name: 'Access From',
                    type: 'pie',
                    radius: '50%',
                    data: Object.values(initData).map((d, did) => {
                        const colors = {
                            'ΠΛΑΣΤΙΚΟ': '#fac858',
                            'ΧΑΡΤΙ': '#5470c6',
                            'ΜΕΤΑΛΛΟ': '#ee6666',
                            'ΓΥΑΛΙ': '#91cc75'
                        };
                        return { ...d, itemStyle: { color: colors[d.name] } };
                    }),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }

                    }
                }],
            });
            return () => {
                pieChart.dispose();
            };
        }
    }, [initData]);

    return (
        <>
            <div ref={pieChartRef}
                 style={{width: '100%', height: '350px', display: 'flex', justifyContent: 'center'}}/>

            {options &&
                <FormControl style={{width: '100px', position: 'absolute', top: '10px', right: '10px'}}>
                    <NativeSelect
                        defaultValue={options[0]}
                        onChange={handleChange}
                        inputProps={{
                            name: '',
                            id: 'uncontrolled-native',
                        }}
                    >
                        {Object.entries(options).map(([key, label]) => (
                            <option key={key.number} value={key}>
                                {label}
                            </option>
                        ))}
                    </NativeSelect>
                </FormControl>
            }
        </>
    );
};
export default CustomPieChart;
