import React, {useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import {Box, Button, Tooltip, Typography, useTheme} from "@mui/material";
import {tokens} from "../theme";
import Grid from "@mui/material/Unstable_Grid2";
import {createMRTColumnHelper, MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {MRT_Localization_EL} from "material-react-table/locales/el";
import {deletePoints, retrievePoints, updatePoints} from "../api/managePoints";
import EditIcon from '@mui/icons-material/Edit';
import {toast} from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import Modal from "react-bootstrap/Modal";
import CustomModal from "./CustomModal";
import AddPoints from "../components/AddPoints";
import {RemoveCircle} from "@mui/icons-material";


const PointsManage = (loading, setLoading) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);


    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false);
        retrievePoints()
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        retrievePoints()
            .then((res) => {
                setData(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);


    const columnHelper = createMRTColumnHelper();
    const columns = [
        columnHelper.accessor('_id', {
            header: 'ID',
            size: 40,
            enableEditing: false,
        }),
        columnHelper.accessor('name', {
            header: 'Τύπος',
            size: 120,
        }),
        columnHelper.accessor('points', {
            header: 'Βαθμός',
            size: 120,
        }),
    ];

    const table = useMaterialReactTable({
        columns,
        data,
        renderTopToolbarCustomActions: ({table}) => (
            <Box
                sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    onClick={() => {
                        // const rows = table.getSelectedRowModel().rows.length === 0 ? table.getPrePaginationRowModel().rows : table.getSelectedRowModel().rows
                        openModal()
                    }
                    }
                    startIcon={<AddIcon/>}
                >
                    νεα καταχωρηση
                </Button>
            </Box>
        ),
        onEditingRowSave: ({table, values}) => {
            const body = {
                name: values.name,
                points: values.points
            }
            updatePoints(values._id, body)
                .then((_) => {
                    toast.success("Επιτυχής Ενημέρωση")
                    retrievePoints()
                        .then((res) => {
                            setData(res.data)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
            table.setEditingRow(null);
        },
        enableEditing: true,
        editDisplayMode: 'row',
        renderRowActions: ({row}) => (
            <Box sx={{display: 'flex', gap: '1rem'}}>
                <Tooltip title={<span style={{fontSize: '14px'}}>Επεξεργασία </span>}><EditIcon
                    style={{cursor: 'pointer'}} onClick={() => table.setEditingRow(row)}/></Tooltip>
                <Tooltip title={<span style={{fontSize: '14px'}}>Διαγραφή </span>}><RemoveCircle
                    style={{cursor: 'pointer', color: '#b21a1b'}} onClick={() => {
                    deletePoints(row.original._id)
                        .then((_) => {
                            toast.success("Επιτυχής Διαγραφή")
                            retrievePoints()
                                .then((res) => {
                                    setData(res.data)
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error("Σφάλμα")
                        })
                }}/></Tooltip>
            </Box>
        ),
        positionActionsColumn: 'last',
        columnFilterDisplayMode: 'popover',
        paginationDisplayMode: 'pages',
        positionToolbarAlertBanner: 'bottom',
        localization: {...MRT_Localization_EL},

    });
    return (
        <div style={{height: '92vh'}}>
            <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
                style={{marginLeft: '20px'}}
            >
                Διαχείριση Βαθμών
            </Typography>
            <Box m="20px">

                <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                    <Grid xs={12}
                          sm={12}
                          md={8}
                          lg={12}
                          container
                          rowSpacing={1}
                          columnSpacing={{xs: 1, sm: 2, md: 3}}>
                        <Grid xs={12}>
                            <Box backgroundColor={colors.primary[400]} style={{borderRadius: '10px'}}>
                                <Box mt="25px"
                                     p="0 30px"
                                     display="flex"
                                     justifyContent="space-between"
                                     alignItems="center">
                                    <Box style={{padding: '10px', width: '100%'}}>
                                        <MaterialReactTable table={table}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Modal show={modalIsOpen}
                               onHide={closeModal}
                               size="lg"
                               aria-labelledby="contained-modal-title-vcenter"
                               centered>
                            <CustomModal title='Δημιουργία Βαθμών' closeModal={closeModal}>
                                <AddPoints/>
                            </CustomModal>
                        </Modal>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default PointsManage;
