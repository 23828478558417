import {axiosInstance} from "../boot/axios";


export async function retrievePoints() {
    return await axiosInstance.get("/api/retrieve_points")
}

export async function getTypes() {
    return await axiosInstance.get("/api/get_available_names")
}

export async function updatePoints(id, body) {
    return await axiosInstance.patch(`/api/points/${id}`, body)
}

export async function addPoints(body) {
    return await axiosInstance.post(`/api/add_points`, body)
}

export async function deletePoints(id) {
    return await axiosInstance.delete(`/api/delete_points/${id}`)
}

export async function createRfid(id, body) {
    return await axiosInstance.patch(`/api/update_rfids/${id}`, body)
}

export async function deleteRfid(id, body) {
    return await axiosInstance.delete(`/api/delete_rfid/${id}/rfid/`, {data: body})
}
