import {createContext, useContext, useMemo, useState} from "react";
import Cookies from 'js-cookie';

const AuthContext = createContext();

export function AuthProvider({children}) {
    const initUser = Cookies.get('user')
    const [user, setUser] = useState(initUser ? JSON.parse(initUser) : null)

    const authContextValue = useMemo(() => ({user, setUser}), [user]);

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    )
}

export default function useAuth() {
    return useContext(AuthContext)
}
