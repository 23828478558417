import React, {useState} from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import logo from "../../assets/ensoll-bg.png";
import {userSignUp} from "../../api/user";
import {useNavigate} from "react-router-dom";

const Signup = () => {
    const [values, setValues] = useState({
        email: '',
        password: ''
    });

    const {email, password} = values;

    const handleChange = name => event => {
        setValues({...values, [name]: event.target.value});
    };
    const navigate = useNavigate();
    const clickSubmit = event => {
        event.preventDefault();
        userSignUp(values)
            .then((res) => {
                toast.success(`Επιτυχής Εγγραφή`);
                navigate("/")
            })
            .catch((err) => {
                toast.error(err.response.data.error)
            })

    };

    return (
        <form className="form form-signup">
            <fieldset>
                <img
                    alt="logo"
                    style={{maxWidth: "250px", height: "auto", padding: "20px"}}
                    src={logo}
                />
                <div className="input-block" style={{width: '230px'}}>
                    <label>E-mail</label>
                    <input onChange={handleChange('email')} value={email} type="email" required/>
                </div>
                <div className="input-block" style={{width: '230px'}}>
                    <label>Κωδικός</label>
                    <input onChange={handleChange('password')} value={password} type="password"
                           required/>
                </div>
            </fieldset>
            <button type="button" onClick={clickSubmit} className="btn-signup">Εγγραφή</button>
        </form>
    );
};

export default Signup;
